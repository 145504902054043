//TODO: update with yours
export interface InteractionConfig {
	msgFrom: string;
	msg: string;
	crawl: string;
	crawl2: string;
	secondary: string;
	timeout?: number; // time after which the interaction gets hidden - in ms
	offer?: string;
	receive?: string;
	// obsolete, replaced by the generic mirrored
	horizontal: 'left' | 'right';

	// these are actually on the iframe message level, but we copy them here for convenience
	mirrored?: boolean;
	volume?: number;
}

//TODO: update with yours
export function validateConfig(c: InteractionConfig): boolean {
	if (!c) {
		return false;
	}
	if (!c.timeout) {
		c.timeout = Number(new URLSearchParams(window.location.search).get('timeout') || '20000');
	}
	return true;
}
