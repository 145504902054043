import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import Marquee from "react-fast-marquee";

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	const { config, onLoaded, onDone, shouldPlay } = props;
	const [loaded, setLoaded] = useState(false); // pre loading state
	const soundRef = useRef<HTMLAudioElement>(null);
	// call after preloading is done F1E9DA
	// props.onLoaded();
	// call onDone when your interaction is done or after some timeout

	useEffect(() => {

		if (soundRef.current) {
			setLoaded(true);
		} else {
			window.parent.postMessage({ status: 'error', error: `failed to load audio ${soundRef.current}` }, '*');
		}
	}, [config]);



	useEffect(() => {
		if (!loaded) return;
		onLoaded();
		setTimeout(() => {
			onDone();
			soundRef.current!.pause();
		}, config.timeout || 20_000);
	}, [loaded, config, onDone, onLoaded]);

	useEffect(() => {
		if (!shouldPlay) return;
		soundRef.current!.volume = config?.volume ?? 0.7;
		soundRef.current!.play();
	}, [shouldPlay, config]);


	//fix scroll detection, load only once audio is ready to play, use user audio preferences, adjust timeout, 
	//change shape/pattern of message header
	// add TTS reader for it
	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				<audio src="/breaking.mp3" ref={soundRef} />
				{/* {props.config.msgFrom}: {props.config.msg} */}
				{loaded &&
					<motion.div

						initial={{ opacity: 0, y: 50 }}
						exit={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.2, delay: 0 }}
					>

						<div className="relative w-[800px] h-auto bg-transparent text-white">
							<div className="bg-transparent">
								<div className="text-red-600 font-bold uppercase bg-white max-w-[250px] max-h-max text-3xl border-t border-l border-r border-white shadow-outline">
									Breaking News
								</div>

							</div>
							<div className="border-t border-l border-r border-b border-white shadow-outline">

								<div className="custom-gradient text-white font-bold text-2xl px-4 py-4 flex-wrap break-words break-before-auto">
									{props.config.msg}
								</div>
								<div className="custom-gradient-2 text-white text-2xl uppercase px-4 py-0.5 flex justify-between items-center whitespace-nowrap">
									{props.config.secondary}
									<span className="bg-white text-red-600 px-1 text-2xl font-extrabold uppercase shadow-md font-[Anton] whitespace-nowrap">LIVE from {props.config.msgFrom}</span>

								</div>
								<div className="bg-white text-black text-sm font-bold py-2 overflow-hidden">
									<Marquee direction={"right"} autoFill={true}>
										<div className='px-2 text-xl'>
											{props.config.crawl} 
										</div>
									</Marquee>
								</div>


							</div>
						</div>
					</motion.div>
				}
			</div >
		</AnimatePresence >
	);
}

